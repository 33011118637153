import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, isValidUrl } = validator();

export function validateCreateSender(data) {
  clearErrors();

  data = unref(data);

  checkRequired("country", data);
  checkRequired("message", data);
  return hasErrors.value ? false : true;
}
