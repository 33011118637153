<template>
  <!-- Contacts table -->
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.viber_senders')"
        :showButton="false"
      />

      <!-- Senders table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="senders"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          :paginator="true"
          v-if="senders && senders?.length"
          :rows="10"
          :filters="filters"
          v-model:selection="selectedRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} senders"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          :rowClass="rowClass"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['channel', 'country']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="mappedSenders"
                  :columns="sendersHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="country"
            style="width: 30%"
            :header="$t('xpbx.table-field.country')"
          >
            <template #body="slotProps">
              {{ getCountryName(slotProps?.data?.country_id) }}
            </template>
          </Column>
          <Column
            sortable
            field="channel"
            style="width: 20%"
            :header="$t('xpbx.table-field.channel')"
          >
            <template #body="slotProps">
              {{ slotProps?.data?.channel }}
            </template>
          </Column>
          <Column
            sortable
            field="order_status"
            style="width: 30%"
            :header="$t('xpbx.table-field.sender')"
          >
            <template #body="slotProps">
              {{ slotProps?.data?.order_status }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 20%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />

        <!-- Dialogs -->
        <Dialog
          v-model:visible="deleteRecordsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
          class="p-fluid relative"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center">{{
              $t("notify.xpbx.message.confirm_delete", {
                delete: `sender ${selectedRecord?.id ? selectedRecord.id : ""}`,
              })
            }}</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              text
              @click="deleteRecordsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              text
              @click="deleteSelectedRecords"
            />
          </template>
        </Dialog>

        <!-- Create sender -->
        <Dialog
          v-model:visible="senderDialog"
          :style="{ width: '450px' }"
          :header="$t('xpbx.pages.heading.create_sender')"
          :modal="true"
          :dismissableMask="true"
          class="p-fluid relative"
        >
          <!-- Country -->
          <div class="field mb-2 mt-4 relative">
            <label for="description">{{
              $t("xpbx.labels.select_country")
            }}</label>
            <SelectWithSearch
              v-model="sender.country"
              :options="countryOptions"
              name="country"
              id="country"
              :errors="errors.country"
              :footerLabel="
                $t('label.profile.profileinfo-pwd.body.company-info.country')
              "
            />
            <small class="p-error" v-if="submitted && !!errors?.country?.[0]">{{
              errors?.country?.[0]
            }}</small>
          </div>
          <!-- Message -->
          <div class="field mb-2 mt-4 relative">
            <label for="message">{{ $t("xpbx.labels.message") }}</label>
            <InputText
              id="message"
              type="text"
              v-model="sender.message"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !sender.message }"
            />
            <small class="p-error" v-if="submitted && !!errors?.message?.[0]">{{
              errors?.message?.[0]
            }}</small>
          </div>

          <!-- Channels -->
          <div class="field mb-2">
            <label for="status">{{ $t("xpbx.labels.status") }}</label>
            <form-select
              v-model="sender.channel"
              :options="channels"
              name="status"
              id="status"
              :activeClass="true"
            />
          </div>

          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              text
              @click="senderDialog = false"
            />
            <Button label="Save" icon="pi pi-check" text @click="create" />
          </template>
        </Dialog>

        <!-- Edit sender -->
        <Dialog
          v-model:visible="senderEditDialog"
          :style="{ width: '450px' }"
          :header="$t('xpbx.pages.heading.edit_sender')"
          :modal="true"
          :dismissableMask="true"
          class="p-fluid relative custom-dialog-heading"
        >
          <!-- Country -->
          <div class="field mb-2 mt-4 relative">
            <label for="description">{{
              $t("xpbx.labels.select_country")
            }}</label>
            <SelectWithSearch
              v-model="sender.country"
              :options="countryOptions"
              name="country"
              id="country"
              :errors="errors.country"
              :footerLabel="
                $t('label.profile.profileinfo-pwd.body.company-info.country')
              "
            />
            <small class="p-error" v-if="submitted && !!errors?.country?.[0]">{{
              errors?.country?.[0]
            }}</small>
          </div>

          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              text
              @click="senderDialog = false"
            />
            <Button label="Save" icon="pi pi-check" text @click="create" />
          </template>
        </Dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import useSenders from "@/modules/xpbx/composables/useSenders";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import { validateCreateSender } from "@/composables/auth/senderValidations";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { sendersHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";

export default {
  name: "ViberSenders",

  components: {
    PageHeader,
    TableSettings,
    Loader,
    DateItem,
    Column,
    Button,
    InputText,
    Dialog,
    DataTable,
    IconField,
    InputIcon,
    Export,
  },

  setup() {
    const store = useStore();
    const countries = computed(() => store.getters.countries);
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const senderDialog = ref(false);
    const senderEditDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const { findSenders, deleteSender, createSender, senders, sender } =
      useSenders();

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    const mappedSenders = computed(() => {
      return senders.value.map((item) => {
        const country = getCountryName(item.country_id);
        return {
          ...item,
          country,
        };
      });
    });

    const viberSenders = computed(() => {
      return senders.value.filter(
        (item) => item.channel === "VIBER" || item.channel === "2"
      );
    });

    const resetSender = () => {
      sender.value = {
        description: "",
        message: "",
        country: "",
        channel: "SMS",
      };
      submitted.value = false;
    };

    const getCountryName = (countriID) => {
      if (!countries.value) return "";
      const country = countries.value.find(
        (country) => country.id === countriID
      );
      return country?.country_name || "";
    };

    const createRecord = async () => {
      submitted.value = true;
      const isValid = validateCreateSender(sender.value);

      if (isValid) {
        const country = countries.value.find(
          (x) => x.country_name === sender.value.country
        );

        const countryID = country.id;

        const formData = {
          message: sender.value.message,
          country_id: `${countryID}`,
          channel: sender.value.channel,
        };

        await createSender(formData);
      }
    };

    // const update = async () => {};

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createRecord();
      }
    };

    const openNew = () => {
      isEdit.value = false;
      senderDialog.value = true;
    };

    const closeDialog = (value) => {
      senderDialog.value = value;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteSender(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      selectedRecord.value = null;
      // if (selectedRecord.value?.id === event.data.id) {
      //   const countryName = getCountryName(event.data.country_id);

      //   const newSender = {
      //     ...event.data,
      //     country: countryName || "",
      //   };
      //   sender.value = newSender;
      //   isEdit.value = true;
      //   senderEditDialog.value = true;
      // }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": +data.is_active === 1,
          "table-inactive": +data.is_active === 0,
          "table-selected": isEdit.value && sender?.value?.id === data?.id,
        },
      ];
    };

    const channels = [
      { value: "SMS", name: "SMS" },
      { value: "VIBER", name: "VIBER" },
      { value: "TELEGRAM", name: "TELEGRAM" },
    ];

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(senderDialog, (value) => {
      if (!value) resetSender();
    });

    onMounted(async () => {
      checkTableClick();
      await findSenders();
    });

    return {
      dt,
      sender,
      senders,
      isEdit,
      channels,
      viberSenders,
      mappedSenders,
      rowClass,
      errors,
      filters,
      submitted,
      countries,
      senderDialog,
      senderEditDialog,
      scrollHeight,
      countryOptions,
      selectedRecord,
      selectedRecords,
      getCountryName,
      sendersHeaders,
      deleteRecordsDialog,
      // Methods
      create,
      openNew,
      closeDialog,
      onRowSelect,
      createRecord,
      onRowUnselect,
      resetSender,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>